.SelectedWeek .DayPicker-Month {
  border-collapse: separate;
}
.SelectedWeek .DayPicker-WeekNumber {
  outline: none;
}
.SelectedWeek .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.SelectedWeek .DayPicker-Day--hoverRange {
  background-color: #efefef !important;
  cursor: pointer;
}

.SelectedWeek .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
  border-radius: 0 !important;
  color: #007d32 !important;

  background-color: #f8e600 !important;
  // border-top-color: #ffeb3b;
  // border-bottom-color: #ffeb3b;
  border-left-color: #f8e600;
  border-right-color: #f8e600;
}

.py-2.m-0.form-check {
  cursor: pointer;

  input {
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}
